import React, { useEffect, useState } from 'react'
import CountryField from './CountryField';
import StateField from './StateField';
import Input from 'react-phone-number-input/input'

function Stage({touched,errors,stage,index,stageType,setFieldValue,numberOfStages}) {

    const [countryErrors,setCountryErrors] = useState({
        from:(errors.stages?.length && touched.stages && errors.stages[index]?.from?.country && touched.stages[index]?.from?.country),
        to:(errors.stages?.length && touched.stages && errors.stages[index]?.to?.country && touched.stages[index]?.to?.country)
    })

    const [stateErrors,setStateErrors] = useState({
        from:(errors.stages?.length && touched.stages && errors.stages[index]?.from?.state && touched.stages[index]?.from?.state),
        to:(errors.stages?.length && touched.stages && errors.stages[index]?.to?.state && touched.stages[index]?.to?.state)
    })

    const renderTitle = function(){
        let title = ''
        if(stageType === 'from'){
            title = numberOfStages > 1 ? 'Stage '+(index + 1)+' - Sender Information' : 'Sender Information'
        }else{
            title = numberOfStages > 1 ? 'Stage '+(index + 1)+' - Recipient Information' : 'Recipient Information'
        }
        return <div className="font-medium text-[#000000] text-[16px] leading-[24px] uppercase mb-5">{title}</div>
    }

    const handleFieldChange = function(field,value){
        let newObj = {}
        switch (field) {
            case 'country':
                newObj = {...stage[stageType],country:value}
                break;
            case 'company':
                newObj = {...stage[stageType],company:value}
                break;
            case 'first_name':
                newObj = {...stage[stageType],first_name:value}
                break;
            case 'last_name':
                newObj = {...stage[stageType], last_name:value}
                break;
            case 'street_address':
                newObj = {...stage[stageType],street_address:value}
                break;
            case 'city':
                newObj = {...stage[stageType],city:value}
                break;
            case 'state':
                newObj = {...stage[stageType],state:value}
                break;
            case 'postal_code':
                newObj = {...stage[stageType],postal_code:value}
                break;
            case 'phone_number':
                newObj = {...stage[stageType],phone_number:value}
                break;
            case 'residential':
                newObj = {...stage[stageType],residential:value}
                break;
            case 'apartment':
                newObj = {...stage[stageType], apartment:value}
                break;
            default:
                break;
        }
        if(stageType === 'from'){
            setFieldValue(`stages[${index}.from]`,newObj)
        }else{
            setFieldValue(`stages[${index}.to]`,newObj)
        }
    }

  return (
    <div className={`detail-stage ${stageType === 'from' ? 'stage-from' : 'stage-to mt-3'}`}>
        {renderTitle()}
        <div className="countries-dropoff mt-2">
            <CountryField index={index} country={stageType === 'from' ? stage.from.country : stage.to.country} handleCountryChange={handleFieldChange} error={stageType === 'from' ? countryErrors.from : countryErrors.to}/>
        </div>
        <div className='flex flex-row justify-between'>
            <div className="relative mb-5 w-full">
                <input
                    id={`stages[${index}][${stageType}].company`} type="text" autocomplete="organization" maxlength="50" name={`stages[${index}][${stageType}].company`} value={stage[stageType].company} onChange={(e)=>handleFieldChange('company',e.target.value)}
                    className="text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0"
                    placeholder=" "
                />
                <label
                    htmlFor={`stages[${index}][${stageType}].company`} className="absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent">
                    Company (optional)
                </label>
            </div>
            {/* <div className={`input-group mb-2 w-1/2 pl-1`}>
                <label className='input-group_checkbox'>Residential
                    <input type='checkbox' name={`stages[${index}][${stageType}].residential`} checked={stage[stageType].residential} onChange={(e)=>handleFieldChange('residential',e.target.checked)} className='w-full text-base'/>
                    <span className='checkmark'/>
                </label>
            </div> */}
        </div>
        <div className="flex flex-row justify-between md:gap-2 flex-wrap md:flex-nowrap">
            <div className="relative mb-5 md:basis-1/2 basis-[100%] grow">
                <input
                    id={`stages[${index}][${stageType}].first_name]`} type="text" autocomplete="given-name" maxlength="50" name={`stages[${index}][${stageType}].first_name]`} value={stage[stageType].first_name} onChange={(e)=>handleFieldChange('first_name',e.target.value)}
                    className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors.stages?.length && touched.stages && errors.stages[index][stageType]?.first_name && touched.stages[index][stageType]?.first_name) && 'border border-[#FF2853]'}`}
                    placeholder=" "
                />
                <label
                    htmlFor={`stages[${index}][${stageType}].first_name]`} 
                    className={`absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent ${(errors.stages?.length && touched.stages && errors.stages[index][stageType]?.first_name && touched.stages[index][stageType]?.first_name) && '!text-[#FF2853]'}`}>
                    First Name *
                </label>
            </div>
            <div className="relative mb-5 md:basis-1/2 basis-[100%] grow">
                <input
                    id={`stages[${index}][${stageType}].last_name]`} type="text" autocomplete="family-name" maxlength="50" name={`stages[${index}][${stageType}].last_name]`} value={stage[stageType].last_name} onChange={(e)=>handleFieldChange('last_name',e.target.value)}
                    className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors.stages?.length && touched.stages && errors.stages[index][stageType]?.last_name && touched.stages[index][stageType]?.last_name) && 'border border-[#FF2853]'}`}
                    placeholder=" "
                />
                <label
                    htmlFor={`stages[${index}][${stageType}].last_name]`} 
                    className={`absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent ${(errors.stages?.length && touched.stages && errors.stages[index][stageType]?.last_name && touched.stages[index][stageType]?.last_name) && '!text-[#FF2853]'}`}>
                    Last Name *
                </label>
            </div>
        </div>
        <div className="flex flex-row justify-between md:gap-2 flex-wrap md:flex-nowrap">
            <div className="relative mb-5 md:basis-2/3 basis-[100%]">
                <input
                    id={`stages[${index}][${stageType}].street_address`} type="text" autocomplete="street-address" maxlength="50" name={`stages[${index}][${stageType}].street_address`} value={stage[stageType].street_address} onChange={(e)=>handleFieldChange('street_address',e.target.value)}
                    className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors.stages?.length && touched.stages && errors.stages[index][stageType]?.street_address && touched.stages[index][stageType]?.street_address) && 'border border-[#FF2853]'}`}
                    placeholder=" "
                />
                <label
                    htmlFor={`stages[${index}][${stageType}].street_address`} 
                    className={`absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent ${(errors.stages?.length && touched.stages && errors.stages[index][stageType]?.street_address && touched.stages[index][stageType]?.street_address) && '!text-[#FF2853]'}`}>
                    Street Address *
                </label>
            </div>
            <div className="relative mb-5 md:basis-1/3 basis-[100%]">
                <input
                    id={`stages[${index}][${stageType}].apartment`} type="text" autocomplete="address-line2" maxlength="100" name={`stages[${index}][${stageType}].apartment`} value={stage[stageType].apartment} onChange={(e)=>handleFieldChange('apartment',e.target.value)}
                    className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0`}
                    placeholder=" "
                />
                <label
                    htmlFor={`stages[${index}][${stageType}].apartment`} 
                    className={`absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent`}>
                    Apartment, Suite, Floor
                </label>
            </div>
        </div>
        <div className="flex flex-row relative md:gap-2 flex-wrap md:flex-nowrap">
            <div className="relative mb-5 md:basis-1/3 basis-[100%]">
                <input
                    id={`stages[${index}][${stageType}].city`} type="text" autocomplete="locality" maxlength="50" name={`stages[${index}][${stageType}].city`} value={stage[stageType].city} onChange={(e)=>handleFieldChange('city',e.target.value)}
                    className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors.stages?.length && touched.stages && errors.stages[index][stageType]?.city && touched.stages[index][stageType]?.city) && 'border border-[#FF2853]'}`}
                    placeholder=" "
                />
                <label
                    htmlFor={`stages[${index}][${stageType}].city`} 
                    className={`absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent ${(errors.stages?.length && touched.stages && errors.stages[index][stageType]?.city && touched.stages[index][stageType]?.city) && '!text-[#FF2853]'}`}>
                    City *
                </label>
            </div>
            <StateField index={index} state={stageType === 'from' ? stage.from.state : stage.to.state} country={stageType === 'from' ? stage.from.country : stage.to.country} handleStateChange={handleFieldChange} error={stageType === 'from' ? stateErrors.from : stateErrors.to} /> 
            <div className="relative mb-5 md:basis-1/3 basis-[100%]">
                <input
                    id={`stages[${index}][${stageType}].postal_code`} type="text" autocomplete="postal-code" maxlength="15" name={`stages[${index}][${stageType}].postal_code`} value={stage[stageType].postal_code} onChange={(e)=>handleFieldChange('postal_code',e.target.value)}
                    className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors.stages?.length && touched.stages && errors.stages[index][stageType]?.city && touched.stages[index][stageType]?.city) && 'border border-[#FF2853]'}`}
                    placeholder=" "
                />
                <label
                    htmlFor={`stages[${index}][${stageType}].postal_code`} 
                    className={`absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent ${(errors.stages?.length && touched.stages && errors.stages[index][stageType]?.city && touched.stages[index][stageType]?.city) && '!text-[#FF2853]'}`}>
                    Postal Code *
                </label>
            </div>
        </div>
        <div className="relative mb-5">
            {/* <input
                id={`stages[${index}][${stageType}].phone_number`} type="text" autocomplete="tel" maxlength="20" name={`stages[${index}][${stageType}].phone_number`} value={stage[stageType].phone_number} onChange={(e)=>handleFieldChange('phone_number',e.target.value)}
                className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors.stages?.length && touched.stages && errors.stages[index][stageType]?.phone_number && touched.stages[index][stageType]?.phone_number) && 'border border-[#FF2853]'}`}
                placeholder=" "
            /> */}
            <Input id={`stages[${index}][${stageType}].phone_number`} 
            country='US'
            maxlength="20"
            placeholder=" "
            onChange={(val)=>handleFieldChange('phone_number',val)}
            className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors.stages?.length && touched.stages && errors.stages[index][stageType]?.phone_number && touched.stages[index][stageType]?.phone_number) && 'border border-[#FF2853]'}`}
            />
            <label
                htmlFor={`stages[${index}][${stageType}].phone_number`} 
                className={`absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent ${(errors.stages?.length && touched.stages && errors.stages[index][stageType]?.phone_number && touched.stages[index][stageType]?.phone_number) && '!text-[#FF2853]'}`}>
                Phone Number *
            </label>
        </div>
    </div>
  )
}

export default Stage