const options = {
    fields: ["formatted_address", "geometry", "name", "address_components", "vicinity"],
    strictBounds: !1
};

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain:jensonbikeshipping.com;secure";
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function checkCookie(cname) {
  let cookie = getCookie(cname);
  if (cookie !== "") {
    return true
  } else {
    return false
  }
}

export function setActiveSearch(values){
  window.localStorage.setItem('activeSearch',JSON.stringify(values))
}

export function getSize(l,w,h,units){
    if(units.length === 'cm'){
        l = convertInchesCentimeters('cm','in',l)
        w = convertInchesCentimeters('cm','in',w)
        h = convertInchesCentimeters('cm','in',h)
    }
    return  l + (2*w) + (2*h)
}

export function convertPoundsKilograms(from,to,value) {
    if(from === 'lb' && to === 'kg'){
        var kilograms = value * 0.453592;
        return Math.round(kilograms);
    }else if(from === 'kg' && to === 'lb'){
        var pounds = value / 0.453592;
        return Math.round(pounds)
    }
}

export function convertInchesCentimeters(from,to,value){

    if(from === 'in' && to === 'cm'){
        const centimeters = value * 2.54;
        return  Math.round(centimeters)
    }else if(from === 'cm' && to === 'in'){
        const inches = value / 2.54;
        return Math.round(inches)
    }
}

export function formatObjectWithUnits(units,item){
    const newObj = {}
    if(units.length === item.linearUnit.toLowerCase()){
        newObj.length = item.length;
        newObj.width = item.width;
        newObj.height = item.height;
        newObj.linearUnit = units.length;
    }else{
        newObj.length = item.length ? convertInchesCentimeters(item.linearUnit.toLowerCase(),units.length,item.length) : item.length
        newObj.width = item.width ? convertInchesCentimeters(item.linearUnit.toLowerCase(),units.length,item.width) : item.width
        newObj.height = item.height ? convertInchesCentimeters(item.linearUnit.toLowerCase(),units.length,item.height) : item.height
        newObj.linearUnit = units.length
    }
    if(units.weight === item.weightUnit.toLowerCase()){
        newObj.weight = item.weight
        newObj.weightUnit  = units.weight
    }else{
        newObj.weight = item.weight ? convertPoundsKilograms(item.weightUnit.toLowerCase(),units.weight,item.weight) : item.weight
        newObj.weightUnit = units.weight
    }
    return newObj
}

export function getFormattedAddressByPlace(place){
    const {address_components, formatted_address,name} = place
    let politicalDevision1 = '',politicalDevision2 = '', street_number='',route='',countryCode = '',postcodePrimaryLow = '',addressLine = '',region='';
    address_components.forEach((component)=>{
      const componentType = component.types[0];
      switch (componentType) {
        case 'administrative_area_level_1':
          politicalDevision1 = component.short_name
          break;
        case 'locality':
          politicalDevision2 = component.short_name
          break;
        case 'postal_code':
          postcodePrimaryLow = component.short_name
          break;
        case 'country':
          countryCode = component.short_name
          break;
        case 'street_number':
          street_number = component.short_name
          break;
        case 'route':
          route = component.short_name
          break;
        default:
          break;
      }
    })
    addressLine = formatted_address
    region= politicalDevision1 + ' ' + postcodePrimaryLow ?? '';
    return {addressLine,region,politicalDevision1,politicalDevision2,postcodePrimaryLow,countryCode,street_number,route,name}
}

export function getFormattedAddressByPlaceAnother(place){
  const {address_components, formatted_address,name} = place
  let politicalDevision1 = '',politicalDevision2 = '', street_number='',route='',country = '',postcodePrimaryLow = '',addressLine = '',region='',city='';
  address_components.forEach((component)=>{
    const componentType = component.types[0];
    switch (componentType) {
      case 'administrative_area_level_1':
        politicalDevision1 = {
          short_name: component.short_name,
          long_name: component.long_name
        }
        break;
      case 'administrative_area_level_2':
        politicalDevision2 = {
          short_name: component.short_name,
          long_name: component.long_name
        }
        break;
      case 'postal_code':
        postcodePrimaryLow = component.short_name
        break;
      case 'country':
        country = {
          short_name:component.short_name,
          long_name:component.long_name
        }
        break;
      case 'street_number':
        street_number = component.short_name
        break;
      case 'route':
        route = component.short_name
        break;
      case 'locality':
        city = component.long_name
        break;
      default:
        break;
    }
  })
  addressLine = formatted_address
  region= politicalDevision1 + ' ' + postcodePrimaryLow ?? '';
  return {addressLine,region,politicalDevision1,politicalDevision2,postcodePrimaryLow,country,street_number,route,name,city}
}

export const constructBoxesObjectForRates = function(boxes){
  let totalWeight = 0;
  const newObj = boxes.map((box)=>{
    totalWeight += parseInt(box.weight)
    return {
      contentType: box?.contents?.name,
      marketValue: box?.marketValue,
      dimensions: {
        unitOfMeasurement: box?.linearUnit === 'in' ? 'IN' : 'CM',
        length: box?.length?.toString(),
        width: box?.width?.toString(),
        height: box?.height?.toString()
      },
      weightUnitOfMeasurement: box?.weightUnit === 'lb' ? 'LBS' : 'KGS',
      weight: box?.weight?.toString(),
      insuranceOptionId: box?.premiumProtection?.id
    }
  })
  const shipmentTotalWeight = {
    unitOfMeasurement: boxes[0]?.weightUnit === 'lb' ? 'LBS' : 'KGS',
    weight: totalWeight.toString()
  }
  return {packages:newObj,shipmentTotalWeight}
}

export const constructTripsObjectForRates = function(trips){
  const newObj = trips.map((trip)=>{
    const addressTo = getFormattedAddressByPlace(trip?.to)
    const addressFrom = getFormattedAddressByPlace(trip?.from)
    return {
      to: {
        name: "",
        attentionName: "",
        address: {
          addressLine: (addressTo.street_number || addressTo.route) ? [addressTo.street_number + ' ' + addressTo.route] : [''],
          city: addressTo.politicalDevision2,
          stateProvinceCode: addressTo.politicalDevision1,
          postalCode: addressTo.postcodePrimaryLow,
          countryCode: addressTo.countryCode
        }
      },
      from: {
        name: "",
        attentionName: "",
        address: {
          addressLine: (addressFrom.street_number || addressFrom.route) ? [addressFrom.street_number + ' ' + addressFrom.route] : [''],
          city: addressFrom.politicalDevision2,
          stateProvinceCode: addressFrom.politicalDevision1,
          postalCode: addressFrom.postcodePrimaryLow,
          countryCode: addressFrom.countryCode
        }
      },
    }
  })
  return newObj
}

export function create32BitId() {
  // Generate a random 32-bit integer
  const randomInt = Math.floor(Math.random() * 0xFFFFFFFF);
  // Format it as a string with leading zeros to ensure it's 32 bits
  const idString = ("00000000" + randomInt.toString(16)).slice(-8);
  return idString;
}

export function addBusinessDays(date, days) {
  let result = new Date(date);
  let addedDays = 0;

  while (addedDays < days) {
    result.setDate(result.getDate() + 1);
    const dayOfWeek = result.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Exclude Sundays (0) and Saturdays (6)
      addedDays++;
    }
  }

  return result;
}

export function formatDateToLocalISOString(date) {


  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  
  return formattedDate;
  // return `${year}-${month}-${day}`;
}

export function getTimezoneOffset(){
  const date = new Date();
  const offsetMinutes = date.getTimezoneOffset(); // Offset in minutes
  const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60); // Hours part of offset
  const offsetMinutesPart = Math.abs(offsetMinutes) % 60; // Minutes part of offset

  const sign = offsetMinutes > 0 ? "-" : "+"; // Adjust for behind/ahead of UTC
  const formattedOffset = `${sign}${String(offsetHours).padStart(2, '0')}`;
  return formattedOffset;
}